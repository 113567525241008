import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PublicPaymentPlanService {
  private apiPrefix = '/public/payment-plan';

  constructor(
    private api: ApiService,
  ) {
  }

  getListablePeriods() {
    return this.api.get(`${this.apiPrefix}/listable`);
  }
}
